export const sendCommand = function (params, isDelay) {
  if (window.iBrowser) {
    if (isDelay) {
      setTimeout(function () {
        console.log('sendCommand:' + params)
        window.iBrowser.sendCommand('loadappwebui', params)
      }, 500)
    } else {
      console.log('sendCommand:' + params)
      window.iBrowser.sendCommand('loadappwebui', params)
    }
  }
}
