function createKeyboardEvent (type, keyCode) {
  const evtObj = new Event(type, {
    bubbles: true,
    cancelBubble: true,
    cancelable: true
  })
  evtObj.keyCode = keyCode
  return evtObj
}
export function Keyboard (obj) {
  console.log(obj)
  if (typeof (obj) !== 'object') {
    console.log('Ksyboard need an object arguments')
    return
  }
  const el = obj.el
  var inputEl = obj.inputEl
  // 初始化Keyboard
  var nomalkeys = [
    ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Backspace'],
    ['Tab', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'],
    ['CapsLock', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\'', 'Enter'],
    ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', 'Shift'],
    ['Space']
  ]
  var Capskeys = [
    ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Backspace'],
    ['Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']', '\\'],
    ['CapsLock', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', '\'', 'Enter'],
    ['Shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '/', 'Shift'],
    ['Space']
  ]
  var ShiftKeys = [
    ['~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', 'Backspace'],
    ['Tab', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '{', '}', '|'],
    ['CapsLock', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ':', '"', 'Enter'],
    ['Shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '<', '>', '?', 'Shift'],
    ['Space']
  ]
  var CapShiftKeys = [
    ['~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', 'Backspace'],
    ['Tab', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '{', '}', '|'],
    ['CapsLock', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ':', '"', 'Enter'],
    ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '<', '>', '?', 'Shift'],
    ['Space']
  ]
  // 需要添加keyboard的元素
  var contentEl = el
  // 绘制键盘
  var drawKeyboard = function (keys) {
    // 键盘
    var keyboardEl = '<div class="keyboard">'
    for (var i = 0; i < keys.length; i++) {
      var lineKeys = keys[i]
      keyboardEl += '<div class = "keyboard-lines">'
      for (var j = 0; j < lineKeys.length; j++) {
        keyboardEl += '<div tabindex="1" class="keyboard-keys keyboard-keys-' + lineKeys[j] + '">' + lineKeys[j] + '</div>'
      }
      keyboardEl += '</div>'
    }
    keyboardEl += '</div>'
    // 添加到元素内
    contentEl.innerHTML = keyboardEl
  }
  // 初始化键盘
  drawKeyboard(nomalkeys)
  var bindDrawKeyboard = drawKeyboard.bind(this)
  // 获取shift 和CapsLock 按键
  // shift
  var shiftArray = document.getElementsByClassName('keyboard-keys-Shift')
  var CapArray = document.getElementsByClassName('keyboard-keys-CapsLock')
  var isShift = false
  var isCapsLock = false
  bindShiftCapClick()
  bindInputKeysClick(inputEl)
  // 键盘设置
  function switchKeyboard () {
    if (!isShift && !isCapsLock) {
      bindDrawKeyboard(nomalkeys)
      bindShiftCapClick()
      bindInputKeysClick(inputEl)
    } else if (isShift && !isCapsLock) {
      bindDrawKeyboard(ShiftKeys)
      shiftArray[0].classList.add('keyboard-keys-active')
      shiftArray[1].classList.add('keyboard-keys-active')
      bindShiftCapClick()
      bindInputKeysClick(inputEl)
    } else if (!isShift && isCapsLock) {
      bindDrawKeyboard(Capskeys)
      CapArray[0].classList.add('keyboard-keys-active')
      bindShiftCapClick()
      bindInputKeysClick(inputEl)
    } else {
      bindDrawKeyboard(CapShiftKeys)
      shiftArray[0].classList.add('keyboard-keys-active')
      shiftArray[1].classList.add('keyboard-keys-active')
      CapArray[0].classList.add('keyboard-keys-active')
      bindShiftCapClick()
      bindInputKeysClick(inputEl)
    }
  }
  var shiftClickEvents = function () {
    isShift = !isShift
    switchKeyboard()
  }
  var capsClickEvents = function () {
    isCapsLock = !isCapsLock
    switchKeyboard()
  }

  function bindShiftCapClick () {
    shiftArray[0].onclick = function () {
      shiftClickEvents()
      setTimeout(() => {
        shiftArray[0].focus()
      }, 0)
    }
    shiftArray[1].onclick = function () {
      shiftClickEvents()
      setTimeout(() => {
        shiftArray[1].focus()
      }, 0)
    }
    CapArray[0].onclick = function () {
      capsClickEvents()
      setTimeout(() => {
        CapArray[0].focus()
      }, 0)
    }
  };

  function bindInputKeysClick (inputEl) {
    var keysArray = document.getElementsByClassName('keyboard-keys')
    for (var i = 0; i < keysArray.length; i++) {
      (function () {
        keysArray[i].onkeydown = function (e) {
          const target = e.target
          const keyCode = e.keyCode
          // e.stopPropagation()
          if (keyCode === 13) {
            target.click()
          } else if (keyCode === 37) { // left
            if (target.previousSibling) {
              target.previousSibling.focus()
            }
          } else if (keyCode === 39) { // right
            if (target.nextSibling) {
              target.nextSibling.focus()
            }
          } else if (keyCode === 38) { // up
            const par = target.parentNode
            if (par.previousSibling) {
              par.previousSibling.firstChild.focus()
            } else {
              inputEl.focus()
            }
          } else if (keyCode === 40) { // down
            const par = target.parentNode
            if (par.nextSibling) {
              par.nextSibling.firstChild.focus()
            }
          } else if (keyCode === 8) { // return
            inputEl.focus()
            const e = createKeyboardEvent('keydown', 8)
            document.activeElement.dispatchEvent(e)
          }
        }
        switch (keysArray[i].innerHTML) {
          case 'Shift':
          case 'CapsLock':
          case 'Enter':
            break
          case 'Tab':
            keysArray[i].onclick = function () {
              inputEl.value += '  '
            }
            break
          case 'Space':
            keysArray[i].onclick = function () {
              inputEl.value += ' '
            }
            break
          case 'Backspace':
            keysArray[i].onclick = function () {
              inputEl.value = inputEl.value.substr(0, inputEl.value.length - 1)
            }
            break
          default:
            keysArray[i].onclick = function () {
              inputEl.value += this.innerHTML
              inputEl.dispatchEvent(new Event('input'))
            }
            break
        }
      })(i)
    }
  }
}
// Keyboard.prototype.bindInput = function (inputId) {
//     var inputEl = document.getElementById("inputId")
//     var keysArray = document.getElementsByClassName("keyboard-keys");
//     for (var i = 0; i < keysArray.length; i++) {
//         (function () {
//             switch (keysArray[i].innerHTML) {
//                 case 'Shift':
//                 case 'CapsLock':
//                     break;
//                 default:
//                     keysArray[i].onclick = function () {
//                         console.log(this);
//                     }
//                     break;

//             }
//         })(i)
//     }
// }
