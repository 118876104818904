export const keyMap = {
  38: 'up',
  40: 'down',
  37: 'left',
  39: 'right',
  13: 'ok',
  8: 'return',
  462: 'return',
  27: 'return',
  36: 'home',
  35: 'model',
  41: 'blueOk',
  50: 'pair',
  192: 'record'
}
