<template>
  <div id="app">
    <record ref="record"></record>
    <router-view />
    <object
      id="DemoPlugin"
      type="brown/home"
      style="visibility: hidden; width: 0px; height: 0px"
    ></object>
    <object
      id="uartplugin"
      type="brown/uartplugin"
      style="visibility: hidden; width: 0px; height: 0px"
    ></object>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
import { signalSelect, getWifiList } from '@/util/wifiUtil.js'
import { sendCommand } from '@/util/index.js'
import record from './components/record.vue'
import test from '@/test'
export default {
  components: { record },
  mounted () {
    let index = 0
    this.$plugin.init(
      document.getElementById('DemoPlugin'),
      document.getElementById('uartplugin')
    )
    window.addEventListener('keydown', (e) => {
      console.log(e.keyCode)
      const key = keyMap[e.keyCode]
      if (key === 'home') {
        sendCommand('hdmi_static', true)
        this.$router.replace('/')
      } else if (key === 'model') {
        if (index < 4) {
          index++
        } else {
          index = 1
        }
        this.changPage(index)
      } else if (key === 'pair') {
        index = 2
        this.changPage(index)
      } else if (key === 'record') {
        this.$refs.record.showRecordSwitch()
      }
    })
    this.$plugin.event.addEventListener('uartResolve', this.resolve)
    test()
    this.init()
  },
  methods: {
    resolve (data) {
      if (
        data.type === 'volume' ||
        data.type === 'micVolume' ||
        data.type === 'echoVolume'
      ) {
        this.$volume.open(...this.$plugin.volumes)
      }
    },
    changPage (index) {
      let url = ''
      switch (index) {
        case 1:
          url = '/'
          break
        case 2:
          url = '/blueTooth'
          break
        case 3:
          url = '/auxin'
          break
        case 4:
          url = '/usb'
          break
      }
      sendCommand('hdmi_static', true)
      this.$router.push(url)
    },
    init () {
      const DemoPlugin = this.$plugin.el
      if (window.iBrowser) {
        this.$plugin.el.i2sinit()
      }
      DemoPlugin.addEventListener('WifiConnectResult', (result) => {
        const connectResult = JSON.parse(result)
        console.log('wifiResult:' + result)
        if (connectResult.state === 1) {
          this.$store.commit('updateWifistate', true)
          this.$store.commit('updateSignal', signalSelect(connectResult))
        } else {
          this.$store.commit('updateWifistate', false)
          this.$plugin.clearWifi()
          if (this.$route.path !== '/setting/wifi') {
            this.$plugin.reconnectWifi()
          }
        }
      })
      if (window.iBrowser) {
        // 判断当前wifi是否连接

        // this.$store.commit('updateEthNetStatus', DemoPlugin.eth_status)
        // 同步时间
        DemoPlugin.System_Command('ntpd -p ntp.aliyun.com &')
        // 监听wifi连接事件

        DemoPlugin.addEventListener('EthNetStatus', (result) => {
          console.log('EthNetStatus:' + result)
          this.$store.commit('updateEthNetStatus', result === 'yes')
        })
        this.initConnect()
      }
    },
    initConnect () {
      const wifiList = getWifiList()
      if (wifiList.length > 0) {
        const saveWifiObj = wifiList[wifiList.length - 1]
        console.log('初始化自动连接wifi。。。。。。。' + saveWifiObj.ssid)
        this.$plugin
          .connectWifi(saveWifiObj.ssid, saveWifiObj.pwd)
          .finally((e) => {
            console.log(e)
          })
      }
    }
  },
  beforeDestroy () {
    this.$plugin.event.removeEventListener('uartResolve', this.resolve)
  }
}
</script>
<style lang="scss">
#app {
  width: 1280px;
  height: 750px;
  overflow: hidden;
  background: black;
  ol,
  ul {
    margin: 0;
    list-style: none;
  }
}
* {
  outline: none;
  margin: 0;
  padding: 0;
}
</style>
