<template>
  <div class="wifiSignal">
    <!-- <img
      :src="
        ethNetStatus
          ? require('@/assets/picture/net.png')
          : require('@/assets/picture/noNet.png')
      "
    /> -->
    <i class="iconfont1 item" :style="{
      display:$store.state.blueToothState?'block':'none'
    }">&#xe65f;</i>
    <img
      :src="
        !wifiState
          ? require('@/assets/picture/wifi-off.png')
          : require('@/assets/picture/wifi_' + signal + '.png')
      "
    />
    <date class="timeTag" />
  </div>
</template>
<script>
import date from './date.vue'
import { mapState } from 'vuex'
// import { signalSelect } from '@/util/wifiUtil.js'
export default {
  components: { date },
  data () {
    return {}
  },
  computed: {
    ...mapState(['wifiState', 'signal'])
  },
  mounted () {
    // const DemoPlugin = this.$plugin.el
    this.$plugin.send({
      data: [0x55, 0xaa, 0x02, 0xaa, 0x00, 0x16]
    })
    // DemoPlugin.addEventListener('WifiConnectResult', (result) => {
    //   const connectResult = JSON.parse(result)
    //   console.log('wifiResult:' + result)
    //   if (connectResult.state === 1) {
    //     this.$store.commit('updateWifistate', true)
    //     this.signal = signalSelect(connectResult)
    //   } else {
    //     this.$store.commit('updateWifistate', false)
    //     this.$plugin.clearWifi()
    //   }
    // })
    // if (window.iBrowser) {
    //   // 判断当前wifi是否连接

    //   this.ethNetStatus = DemoPlugin.eth_status
    //   // 同步时间
    //   DemoPlugin.System_Command('ntpd -p ntp.aliyun.com &')
    //   // 监听wifi连接事件

    //   DemoPlugin.addEventListener('EthNetStatus', (result) => {
    //     this.ethNetStatus = result === 'yes'
    //   })
    //   this.initConnect()
    // }
  },
  methods: {
    initConnect () {
      const saveWifi = localStorage.getItem('saveWifi')
      if (saveWifi) {
        const saveWifiObj = JSON.parse(saveWifi)
        console.log('初始化自动连接wifi。。。。。。。' + saveWifiObj.ssid)
        this.$plugin
          .connectWifi(saveWifiObj.ssid, saveWifiObj.pwd)
          .finally((e) => {
            console.log(e)
          })
      }
    }
  },
  beforeDestory () {}
}
</script>
<style lang="scss" scoped>
.wifiSignal {
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  display: -webkit-flex;
  justify-content: center;
  img {
    display: block;
    width: 40px;
    height: 40px;
    margin: 10px;
  }
  .item{
    display: block;
    font-size: 55px;
    color: blue;
  }
  .timeTag {
    height: 40px;
    line-height: 40px;
    margin: 10px 25px 10px 55px;
    color: #bfbfbf;
    font-size: 35px;
  }
}
</style>
