<template>
  <button  @click="handleClick" @keydown="handleKeyDown" ref="button">
    <span class="loader" v-show="isLoading"></span>
    <span :style="{
      'margin-left':isLoading?'30px':'0px'
    }">
      <slot></slot>
    </span>
  </button>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  name: 'myButton',
  methods: {
    handleClick (evt) {
      this.$emit('click', evt)
      evt.preventDefault()
    },
    handleKeyDown (evt) {
      this.$emit('keydown', evt)
      evt.preventDefault()
    },
    focus () {
      this.$refs.button.focus()
    }
  }
}
</script>
<style lang="scss" scoped>
.loader {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border-top: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid #ee2223;
  -webkit-transform: translateZ(0);
  -webkit-animation: loader 600ms infinite linear;
  -webkit-transition: all 600ms ease;
  z-index: 9999;
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border-left: 5px solid #ee2223;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border-left: 5px solid #fc4c2f;
  }
}
</style>
