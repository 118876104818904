import { cuisineUtils, array2hex } from '@/uartParser/index.js'
import EventTarget from '@/lib/EventTarget.js'
import store from '../store'
import { getWifiList, saveWifi, hasSSid } from '@/util/wifiUtil.js'
const wifiListTest = {
  wifilist: [{
    ssid: 'qwerty',
    encrypt: 1,
    signalSTR: -30
  },
  {
    ssid: 'asdfgh',
    encrypt: 1,
    signalSTR: -100
  },
  {
    ssid: 'zxcvbn',
    encrypt: 1,
    signalSTR: -80
  },
  {
    ssid: 'uiop',
    encrypt: 1,
    signalSTR: -90
  },
  {
    ssid: 'iserverNetwork',
    encrypt: 1,
    signalSTR: 0
  },
  {
    ssid: 'jkl',
    encrypt: 1,
    signalSTR: -50
  },
  {
    ssid: 'nm',
    encrypt: 0,
    signalSTR: -60
  },
  {
    ssid: 'xerewvdfrcxvefgggdW',
    encrypt: 0,
    signalSTR: -70
  },
  {
    ssid: 'aer',
    encrypt: 0,
    signalSTR: -20
  },
  {
    ssid: 'awerer',
    encrypt: 0,
    signalSTR: -10
  }
  ]
}

// const hardware = {
//   blueTooth: null,
//   aux: null
// }
export const plugin = {
  el: '',
  volumes: [0, 0, 0],
  blueTooth: 0,
  aux: 0,
  event: new EventTarget(),
  init: function (el, uartplugin) {
    console.log('init...')
    plugin.el = el
    plugin.uartplugin = uartplugin
    if (window.iBrowser) {
      plugin.el.start()
    }
    plugin.uartplugin.uart_init && plugin.uartplugin.uart_init(115200, 115200, 115200)
    plugin.uartplugin.addEventListener('ttyS1_receive', function () {
      plugin.receive(...arguments)
    })
    // 初始化时监听wifi连接结果
  },
  receive () {
    plugin.resolve(arguments, function (data) {
      switch (data.type) {
        case 'volume':
          plugin.volumes[0] = data.num
          break
        case 'micVolume':
          plugin.volumes[1] = data.num
          break
        case 'echoVolume':
          plugin.volumes[2] = data.num
          break
        case 'queryVolume':
          plugin.volumes = data.num
          break
        case 'blueTooth':
          plugin.blueTooth = data.state
          // var state = data.state === 1 ? 'success' : 'failed'
          // $('#blueTip').html('blueTooth is connect ' + state)
          break
        case 'aux':
          plugin.aux = data.state
          // var state = data.state === 1 ? 'success' : 'failed'
          // $('#auxTip').html('aux is connect ' + state)
          break
      }
      plugin.event.fire('uartResolve', data)
    })
  },
  // 获取当前wifi状态
  getWifiState: function () {
    if (window.iBrowser) {
      console.log('wlan_state:' + plugin.el.wlan_state)
      return plugin.el.wlan_state
    }
    return 0
  },
  scanning: false,
  // 扫描wifi
  scanWifi: function () {
    console.log('扫描')
    return new Promise(function (resolve, reject) {
      var callBack = function () {
        if (window.iBrowser) {
          // 移除监听WiFi扫描结果事件
          plugin.el.removeEventListener('WifiScanFinish', callBack)
        }
        var wifiListResult = window.iBrowser ? plugin.el.scan_result : JSON.stringify(wifiListTest)
        // 返回wifi列表数据
        var wifiList = JSON.parse(wifiListResult).wifilist
        console.log('receive scan result:' + JSON.stringify(wifiList))
        resolve(wifiList)
      }
      if (!window.iBrowser) {
        callBack()
      } else {
        plugin.el.addEventListener('WifiScanFinish', callBack)
        plugin.el.StartScan()
      }
    })
  },
  reconnectWifi: async function () {
    if (this.scanning) return
    console.log('重连wifi扫描中')
    this.scanning = true
    const result = await plugin.scanWifi()
    let info = {}
    for (let i = 0; i < result.length; i++) {
      const index = hasSSid(result[i].ssid)
      if (index >= 0) {
        info = getWifiList()[index]
        break
      }
    }
    if (info.ssid) {
      console.log('匹配到wifi:' + info.ssid)
      plugin.connectWifi(info.ssid, info.pwd)
    } else {
      console.log('未配到wifi')
    }
    this.scanning = false
  },
  clearWifi: function () {
    store.commit('updateConnectedSSid', '')
  },
  saveWifi: function (ssid, pwd) {
    saveWifi(ssid, pwd)
    store.commit('updateConnectedSSid', ssid)
    if (window.iBrowser) {
      plugin.el.System_Command('sync')
    }
  },
  disconnect () {
    if (window.iBrowser) {
      plugin.el.Disconnect()
      plugin.clearWifi()
    } else {
      plugin.clearWifi()
    }
    store.commit('updateWifistate', false)
  },
  connectWifi: function (ssid, pwd) {
    return new Promise((resolve, reject) => {
      if (store.state.connecting.state) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ message: store.state.connecting.ssid + 'is connecting,Please try again later' })
        return null
      }
      store.commit('updateConnecting', { ssid: ssid, state: true }) // 保存wifi连接中状态
      if (window.iBrowser) {
        plugin.el.addEventListener('WifiConnectResult', function callback (result) {
          const connectResult = JSON.parse(result)
          console.log('connect result:' + JSON.stringify(connectResult))
          if (connectResult.ssid === ssid) {
            console.log('receive ssid:' + ssid + 'connect result: ' + connectResult.state)
            plugin.el.removeEventListener('WifiConnectResult', callback)
            store.commit('updateConnecting', { ssid: ssid, state: false })
            if (connectResult.state === 1) {
              plugin.el.System_Command('ntpd -p ntp.aliyun.com &')
              plugin.saveWifi(ssid, pwd)
              plugin.el.System_Command('sync')
              resolve(connectResult)
            } else {
              reject(connectResult)
            }
          }
        })
        console.log('start connect ssid:' + ssid)
        plugin.el.StartConnect(ssid, pwd)
      } else {
        setTimeout(() => {
          plugin.saveWifi(ssid, pwd)
          store.commit('updateWifistate', true)
          store.commit('updateConnecting', { ssid: ssid, state: false })
          resolve({ state: 1, signalSTR: 3 })
        }, 1500)
      }
    })
  },
  send: function (dataMsg) {
    Object.keys(dataMsg).forEach(function (key) {
      var message = dataMsg[key]
      var pluginmsg = JSON.stringify({
        protocol: message
      })
      console.log('send:' + array2hex(message))
      plugin.uartplugin.ttyS1_send && plugin.uartplugin.ttyS1_send(pluginmsg)
      if (!window.iBrowser) {
        plugin.event.fire('uartResolve', {})
      }
    })
  },
  resolve: function (data, callback) {
    var array = Array.prototype.slice.call(data)
    console.log('receive:' + array2hex(array))
    array = cuisineUtils.getValidData(array)
    cuisineUtils.analysisData(array, function (data) {
      console.log(data)
      callback && callback(data)
    })
  },
  getFiles: function (dir) {
    const result = window.iBrowser ? this.el.ReadPath(dir) : 'file:a.mp3;file:player.html;file:yanzheng.mp4;file:test.mp4;file:prog_audio_all_test_case;file:48K_16bit_MONO_60s.wav;file:SigmastarUpgrade.bin;file:libbrownplayer.so;file:88x2bu.ko;file:fbdemo;dir:dist1;file:iot_plugin_demo.rar;dir:iot_plugin_demo;dir:customer;dir:lib;dir:dist;file:JpgToYuv.exe;file:._cache_JpgToYuv.exe;file:test.jpeg;'
    console.log('file result:' + result)
    return result.substring(0, result.length - 1).split(';').map((msg) => {
      const info = msg.split(':')
      return {
        title: info[1],
        isDirectory: info[0] === 'dir'
      }
    }).sort((a, b) => {
      if (a.isDirectory && !b.isDirectory) {
        return -1
      } else if (!a.isDirectory && b.isDirectory) {
        return 1
      } else {
        return 0
      }
    })
  }
}
export default {
  install (Vue, options) {
    Object.defineProperty(Vue.prototype, '$plugin', {
      get () { return plugin }
    })
  }
}
