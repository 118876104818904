import { Keyboard } from '@/lib/keyboard.js'
import '@/lib/keyboard.css'

const ime = {
  data () {
    return {}
  },
  methods: {
    init (el) {
      Keyboard({
        inputEl: this.target,
        el: el
      })
      this.$nextTick(() => {
        this.focus()
      })
    },
    focus () {
      if (document.getElementsByClassName('keyboard-keys').length) { document.getElementsByClassName('keyboard-keys')[0].focus() }
    }
  },
  render: function render (h) {
    return h('div', {
      // style: {
      //   position: 'absolute',
      //   width: '100%',
      //   height: '100%',
      //   top: 0
      // }
    })
  }
}
let vm = null
export default {
  install (Vue) {
    const Ime = Vue.extend(ime)
    Vue.prototype.$ime = {
      open (target) {
        if (vm && vm.target === target) {
          return
        } else if (vm && vm.target !== target) {
          this.close()
        }
        const $ime = new Ime({
          data () {
            return {
              target: target
            }
          },
          methods: {
            destroy: () => {
              this.close()
            }
          },
          beforeDestroy () {
            document.body.removeChild(vm.$el)
            vm.target = null
            vm.destroy = null
            vm = null
          }
        })
        vm = $ime.$mount()
        const el = vm.$el

        document.body.appendChild(el)
        vm.init(el)
      },
      close () {
        if (vm) {
          vm.$destroy()
        }
      },
      focus () {
        if (vm) {
          vm.focus()
        }
      }
    }
  }
}
