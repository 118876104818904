import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    connectedSSid: '',
    wifiState: false,
    signal: '0',
    ethNetStatus: false,
    connecting: {
      ssid: '',
      state: false
    },
    blueToothState: false,
    recordVideoState: false,
    recordAudioState: false
  },
  mutations: {
    updateConnectedSSid (state, playod) {
      state.connectedSSid = playod
    },
    updateWifistate (state, playod) {
      state.wifiState = playod
    },
    updateConnecting (state, playod) {
      state.connecting = playod
    },
    updateSignal (state, playod) {
      state.signal = playod
    },
    updateEthNetStatus (state, playod) {
      state.ethNetStatus = playod
    },
    updatRecordVideoState (state, playod) {
      state.recordVideoState = playod
    },
    updatRecordAudioState (state, playod) {
      state.recordAudioState = playod
    },
    updateBTState (state, playod) {
      state.blueToothState = playod
    }
  },
  actions: {
  },
  modules: {
  }
})
