import { plugin } from '../plugins/plugin'
export default function () {
  window.addEventListener('keydown', (e) => {
    const key = e.keyCode
    switch (key) {
      case 87: // 上 w
        plugin.receive(0xAA, 0x55, 0x02, 0x0A, 0x03, 0x16)
        break
      case 83: // 下 s
        plugin.receive(0xAA, 0x55, 0x02, 0x0A, 0x04, 0x16)
        break
      case 65: // 左 a
        plugin.receive(0xAA, 0x55, 0x02, 0x0A, 0x02, 0x16)
        break
      case 68: // 右 d
        plugin.receive(0xAA, 0x55, 0x02, 0x0A, 0x01, 0x16)
        break
      case 81: // ok q
        plugin.receive(0xAA, 0x55, 0x02, 0x0B, 0x00, 0x16)
        break
      case 69: // return e
        plugin.receive(0xAA, 0x55, 0x02, 0x0C, 0x00, 0x16)
        break
      case 82: // volume r
        plugin.receive(0xAA, 0x55, 0x02, 0x03, 0x05, 0x16)
        break
      case 70: // mic f
        plugin.receive(0xAA, 0x55, 0x02, 0x04, 0x06, 0x16)
        break
      case 71: // g echo
        plugin.receive(0xAA, 0x55, 0x02, 0x0D, 0x02, 0x16)
        break
    }
  })
}
