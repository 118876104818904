import { render, staticRenderFns } from "./myButton.vue?vue&type=template&id=4ed1c414&scoped=true&"
import script from "./myButton.vue?vue&type=script&lang=js&"
export * from "./myButton.vue?vue&type=script&lang=js&"
import style0 from "./myButton.vue?vue&type=style&index=0&id=4ed1c414&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed1c414",
  null
  
)

export default component.exports