// eslint-disable-next-line camelcase
import EventTarget from '@/lib/EventTarget'
window.eventBus = new EventTarget()
// eslint-disable-next-line camelcase
var FrameHead_default = [0xAA, 0x55]
// eslint-disable-next-line camelcase
var FrameEnd_default = 0x16
var queue = []
function createKeyboardEvent (type, keyCode) {
  const evtObj = new Event(type, {
    bubbles: true,
    cancelBubble: true,
    cancelable: true
  })
  evtObj.keyCode = keyCode
  // const evtObj = document.createEvent('UIEvents')
  // evtObj.initUIEvent(type, true, true, window, 0)
  // evtObj.keyCode = keyCode
  return evtObj
}
export const cuisineUtils = {
  getValidData: function (data) {
    queue = queue.concat(data)
    return this.resolve()
  },
  resolve: function () {
    var tempEndIndex = 0
    var result = []
    var frame = []

    for (var i = 0; i < queue.length; i++) {
      if (frame.length === 2) {
        var frameLength = queue[i]
        var frameEndIndex = i + frameLength + 1
        if (frameEndIndex + 1 <= queue.length) {
          // eslint-disable-next-line camelcase
          if (queue[frameEndIndex] === FrameEnd_default) {
            queue.slice(i, frameEndIndex + 1).forEach(function (d) {
              frame.push(d)
            })
            result.push(frame)
            i = frameEndIndex
            tempEndIndex = frameEndIndex
            // console.log('完整的一帧：' + array2hex(frame))
            frame = []
          } else {
            frame = []
            i--
            console.log('此帧不正确')
          }
        } else {
          console.log('此帧不完整')
          break
        }
      } else if (queue[i] === FrameHead_default[0] && i + 1 < queue.length && queue[i + 1] === FrameHead_default[1]) {
        frame.push(queue[i])
        frame.push(queue[i + 1])
        i++
      }
    }
    queue = queue.slice(tempEndIndex > 0 ? tempEndIndex + 1 : 0, queue.length)
    return result
  },
  analysisData: function (data, callback) {
    data.forEach(function (iframe) {
      console.log('receive:' + array2hex(iframe))
      let msg = {
        type: '',
        state: 0
      }
      if (iframe.length === 6 && iframe[2] === 0x02 && iframe[3] === 0x01) {
        switch (iframe[4]) {
          case 0x00:
            msg = {
              type: 'blueTooth',
              state: 0
            }
            break
          case 0x02:
            msg = {
              type: 'blueTooth',
              state: 1
            }
            break
          case 0x03:
            msg = {
              type: 'blueTooth',
              state: 2
            }
            break
          case 0x05:
            msg = {
              type: 'aux',
              state: 1
            }
            break
          case 0x06:
            msg = {
              type: 'aux',
              state: 0
            }
            break
        }
        callback && callback(msg)
      } else if (iframe[3] === 0x00 && iframe[4] === 0x00) { // pair 键
        console.log('receive pair key' + iframe[4])
        const e = createKeyboardEvent('keydown', 50)
        console.log(e.keyCode)
        document.activeElement.dispatchEvent(e)
        window.eventBus.fire('message', e)
      } else if (iframe[3] === 0x03) { // 主音量
        msg = {
          type: 'volume',
          num: iframe[4]
        }
        console.log('receive volume ' + iframe[4])
        callback && callback(msg)
      } else if (iframe[3] === 0x04) { // MIC音量
        msg = {
          type: 'micVolume',
          num: iframe[4]
        }
        console.log('receive micVolume ' + iframe[4])
        callback && callback(msg)
      } else if (iframe[3] === 0x0D) { // ECHO音量
        msg = {
          type: 'echoVolume',
          num: iframe[4]
        }
        console.log('receive echoVolume ' + iframe[4])
        callback && callback(msg)
      } else if (iframe[3] === 0x0A) { // 上下左右
        let e
        switch (iframe[4]) {
          case 0x01: // 右
            console.log('receive right key' + iframe[4])
            // e = new KeyboardEvent('keydown', { keyCode: 39, which: 39 })
            e = createKeyboardEvent('keydown', 39)
            break
          case 0x02: // 左
            console.log('receive left key' + iframe[4])
            e = createKeyboardEvent('keydown', 37)
            break
          case 0x03: // 上
            console.log('receive up key' + iframe[4])
            e = createKeyboardEvent('keydown', 38)
            break
          case 0x04: // 下
            console.log('receive down key' + iframe[4])
            e = createKeyboardEvent('keydown', 40)
            break
          case 0x05: // home
            console.log('receive home key' + iframe[4])
            e = createKeyboardEvent('keydown', 36)
            break
          case 0x06: // model
            console.log('receive model key' + iframe[4])
            e = createKeyboardEvent('keydown', 35)
            break
        }
        console.log(e.keyCode)
        document.activeElement.dispatchEvent(e)
        window.eventBus.fire('message', e)
      } else if (iframe[3] === 0x0B) { // ok
        console.log('receive ok key' + iframe[4])
        const e = createKeyboardEvent('keydown', 13)
        document.activeElement.dispatchEvent(e)
        window.eventBus.fire('message', e)
      } else if (iframe[3] === 0x0C) { // return
        console.log('receive return key' + iframe[4])
        const e = createKeyboardEvent('keydown', 8)
        document.activeElement.dispatchEvent(e)
        window.eventBus.fire('message', e)
      } else if (iframe[3] === 0x0E) { // record
        console.log('receive record key' + iframe[4])
        const e = createKeyboardEvent('keydown', 192)
        document.activeElement.dispatchEvent(e)
        window.eventBus.fire('message', e)
      } else if (iframe[3] === 0xaa) { // 查询音量回复
        msg = {
          type: 'queryVolume',
          num: [iframe[4], iframe[5], iframe[6]]
        }
        callback && callback(msg)
        console.log('receive allVolume ' + [iframe[4], iframe[5], iframe[6]].join(','))
      }
    })
  }
}
export const array2hex = function (array) {
  return array.map((x) => ('0x' + (x.toString(16).length > 1 ? x.toString(16) : '0' + x.toString(16)))).join(',')
}
