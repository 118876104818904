import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/main')
      },
      {
        path: '/setting',
        component: () => import('@/views/setting')
      },
      {
        path: '/blueTooth',
        component: () => import('@/views/blueTooth')
      },
      {
        path: '/auxin',
        component: () => import('@/views/auxin')
      },
      {
        path: '/setting/wifi',
        component: () => import('@/views/setting/wifi')
      },
      {
        path: '/setting/update',
        component: () => import('@/views/setting/update')
      },
      {
        path: '/setting/privacy',
        component: () => import('@/views/setting/privacy')
      },
      {
        path: '/setting/service',
        component: () => import('@/views/setting/service')
      },
      {
        path: '/usb',
        name: 'usb',
        props: true,
        component: () => import('@/views/usb')
      },
      {
        path: '/airplay',
        component: () => import('@/views/airplay')
      },
      {
        path: '/tiktok',
        component: () => import('@/views/tiktok')
      },
      {
        name: 'play',
        path: '/play',
        props: true,
        component: () => import('@/views/usb/player.vue')
      }
    ]
  },
  {
    path: '/webview',
    component: () => import('@/views/iframe')
  }
]

const router = new VueRouter({
  routes
})

export default router
