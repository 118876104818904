<template>
  <div>
    <div>{{ title }}<span style="color:#ED0083">({{ num }})</span></div>
    <div class="slider-line">
      <div class="el-slider__bar" :style="'width:' + ratio + '%'"></div>
      <div class="el-slider__bar_white" style="width: 100%"></div>
      <div
        class="el-slider__button-wrapper-cover"
        :style="'left:' + ratio + '%'"
      >
        <div
          tabindex="0"
          class="el-slider__button-wrapper"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'volume',
  props: {
    title: String,
    num: Number,
    total: Number
  },
  computed: {
    ratio: function () {
      return (this.num / this.total) * 100
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$color:#51B9E0;
.slider-line {
  width: 484px;
  height: 6px;
  padding: 19px 0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  .el-slider__bar {
    height: 6px;
    background-color: $color;
    border-radius: 5px;
    position: absolute;
    z-index: 11;
  }
  .el-slider__bar_white {
    height: 6px;
    background-color:white;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
  }
  .el-slider__button-wrapper-cover {
    height: 18px;
    width: 18px;
    position: relative;
    z-index: 1001;
    -webkit-transform: translate(-9px, 0);
    top: -6px;
    .el-slider__button-wrapper {
      position: absolute;
      z-index: 1001;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $color;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $color;
    }
  }
}
</style>
