<template>
  <div class="record" :class="($store.state.recordVideoState||$store.state.recordAudioState)?'twinkle':''" >
    <i class="iconfont1" @click.stop="showRecordSwitch">{{($store.state.recordVideoState||$store.state.recordAudioState)?'&#xe685;':'&#xe652;'}}</i>
    <div class="record_switch" v-show = "switchRecord">
      <span @click.stop="handleRecordVideo">Video</span>
      <span @click.stop="handleRecordAudio">Audio</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      switchRecord: false
    }
  },
  methods: {
    showRecordSwitch () {
      if (this.$store.state.recordVideoState) {
        this.$store.commit('updatRecordVideoState', false)
        if (window.iBrowser) {
          // turn off video record
        }
        console.log('turn off Video recording')
      } else if (this.$store.state.recordAudioState) {
        this.$store.commit('updatRecordAudioState', false)
        if (window.iBrowser) {
          this.$plugin.el.ipc_send('record_end')
        }
        console.log('turn off Audio recording')
      } else {
        this.switchRecord = !this.switchRecord
      }
    },
    handleRecordAudio () {
      if (!this.$store.state.recordAudioState) {
        this.$store.commit('updatRecordAudioState', true)
        if (window.iBrowser) {
          this.$plugin.el.ipc_send('record_begin')
        }
        console.log('turn on Audio recording')
        this.switchRecord = false
      }
    },
    handleRecordVideo () {
      if (!this.$store.state.recordVideoState) {
        this.$store.commit('updatRecordVideoState', true)
        if (window.iBrowser) {
          // turn on video record
        }
        console.log('turn on Video recording')
        this.switchRecord = false
      }
    }
  }
}
</script>
<style scoped>
.record {
  z-index: 10000;
  position: absolute;
  top: 0px;
  right: 120px;
  font-size:45px ;
  opacity: 0.5;
  color: red;
}
.twinkle{
  -webkit-animation: animate 0.5s linear infinite;
}
.record_switch{
  height: 50px;
  line-height: 50px;
  padding:5px 5px;
  color: white;
  position: relative;
  top: 55px;
  left: 115px;
  background-color:rgba(0, 0, 0, 0.7);
  font-size: 25px;
  float: left;
  border-radius: 15px;
}
.record_switch span{
  padding: 10px;
}
@-webkit-keyframes animate{
 0% {
  opacity: 0;
 }
 50%{
  opacity: 0.5;
 }
 100%{
  opacity: 1;
 }
}
</style>
