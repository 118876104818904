<template>
  <div
    class="slider"
    ref="slider"
    @click="onSkipe($event)"
    @mouseenter="enter"
    @mouseleave="leave"
    @mousedown="onButtonDown($event)"
    @touchstart="onButtonDown($event)"
  >
    <div class="slider-p">
      <div class="slider-line">
        <div
          class="el-slider__bar"
          :style="{
            width: num + '%',
            'background-color': bgColor,
          }"
        ></div>
        <div class="el-slider__bar_white" style="width: 100%"></div>
        <div
          class="el-slider__button-wrapper-cover"
          :style="'left:' + num + '%'"
        >
          <div
            tabindex="0"
            class="el-slider__button-wrapper"
            :style="{
              'background-color': bgColor,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function getOffsetSum (elem) {
  var top = 0
  var left = 0
  while (elem) {
    top = top + parseInt(elem.offsetTop)
    left = left + parseInt(elem.offsetLeft)
    elem = elem.offsetParent
  }
  return { top: top, left: left }
}
export default {
  name: 'mySlider',
  props: {
    initNum: {
      type: Number,
      default: 0
    },
    bgColor: {
      type: String,
      default: '#f00'
    }
  },
  data () {
    return {
      num: this.initNum,
      dragging: false,
      startX: 0,
      currentX: 0,
      width: 0,
      isShow: false
    }
  },
  watch: {
    initNum (newVal) {
      this.num = newVal
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.startX = getOffsetSum(this.$refs.slider).left
      this.width = this.$refs.slider.offsetWidth
    },
    enter () {
      this.isShow = true
    },
    leave () {
      this.isShow = false
    },
    // 点击slider,能直接跳转
    onSkipe (event) {
      console.log('点击' + event.clientX)
      // this.getData()
      // let diff = 0;
      // this.currentX = event.clientX;
      // diff = this.currentX - this.startX;
      // this.setPosition(diff);
      // this.$emit("on-skipe", this.num);
    },
    onButtonDown (event) {
      console.log('onButtonDown')
      event.preventDefault()
      this.getData()
      this.onDragStart(event)
      window.addEventListener('mousemove', this.onDragging)
      window.addEventListener('touchmove', this.onDragging)
      window.addEventListener('mouseup', this.onDragEnd)
      window.addEventListener('touchend', this.onDragEnd)
    },
    onDragStart (event) {
      console.log('onDragStart')
      this.dragging = true
      if (event.type === 'touchstart') {
        event.clientX = event.touches[0].clientX
      }
    },
    onDragging (event) {
      console.log('onDragging')
      this.isShow = true
      if (event.type === 'touchmove') {
        event.clientX = event.touches[0].clientX
      }
      let diff = 0
      this.currentX = event.clientX
      diff = this.currentX - this.startX
      this.setPosition(diff)
      this.$emit('on-input', this.num)
    },
    onDragEnd (event) {
      console.log('onDragEnd')
      // if (this.dragging) {
      if (event.type === 'touchend') {
        event.clientX = event.changedTouches[0].clientX
      }
      let diff = 0
      this.currentX = event.clientX
      diff = this.currentX - this.startX
      this.setPosition(diff)
      this.dragging = false
      this.num = Math.round(this.num)
      this.$emit('on-change', this.num)
      window.removeEventListener('mousemove', this.onDragging)
      window.removeEventListener('touchmove', this.onDragging)
      window.removeEventListener('mouseup', this.onDragEnd)
      window.removeEventListener('touchend', this.onDragEnd)
      this.isShow = false
      // }
    },
    setPosition (diff) {
      if (diff <= 0) {
        this.num = 0
      } else if (diff >= this.width) {
        this.num = 100
      } else {
        this.num = (diff / this.width) * 100
      }
    }
  }
}
</script>
<style scoped>
.el-slider__bar {
  height: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  z-index: 11;
}
.el-slider__bar_white {
  height: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  z-index: 10;
}
.el-slider__button-wrapper-cover {
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 1001;
  -webkit-transform: translate(-50%, -40%);
}
.el-slider__button-wrapper {
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #f00;
  text-align: center;
  border-radius: 50%;
}
.slider-line {
  width: 100%;
  height: 5px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.slider {
  height: 20px;
  position: relative;
  padding: 7px 0;
}
.slider-p {
  width: 100%;
}
</style>
