<template>
  <div style="height: 100%;">
    <my-header />
    <router-view />
  </div>
</template>
<script>
import myHeader from './components'
export default {
  components: { myHeader }
}
</script>
