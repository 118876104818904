import volumeItem from '@/components/volume.vue'
const volume = {
  data () {
    return {
      volume: 0,
      micVolume: 0,
      echoVolume: 0
    }
  },
  props: {
    total: Number,
    n1: Number,
    n2: Number,
    n3: Number
  },
  created: function () {
    this.setVolume(this.n1, this.n2, this.n3)
  },
  methods: {
    renderItem (name, volume, total) {
      const h = this.$createElement
      return h(volumeItem, {
        props: {
          title: name,
          num: volume,
          total
        }
      })
    },
    setVolume (n1, n2, n3) {
      this.volume = n1
      this.micVolume = n2
      this.echoVolume = n3
    }
  },
  render: function (h) {
    const children = [this.renderItem('Volume', this.volume, 20),
      this.renderItem('MIC Volume', this.micVolume, 15),
      this.renderItem('ECHO Volume', this.echoVolume, 8)]
    return h('div', {
      style: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        background: 'rgba(0,0,0,0.7)',
        top: 0,
        zIndex: 99999
      }
    }, [
      h('div', {
        style: {
          position: 'absolute',
          left: '50%',
          top: '50%',
          color: 'white',
          'font-size': '20px',
          '-webkit-transform': 'translate(-50%, -50%)'
        }
      }, children)
    ])
  }
}
let vm = null
let t = null
const timerDestroy = () => {
  t = setTimeout(() => {
    if (vm) {
      vm.$destroy()
    }
    t = null
  }, 3000)
}
export default {
  install (Vue, options) {
    const Volume = Vue.extend(volume)
    Vue.prototype.$volume = {
      open (n1, n2, n3) {
        if (t) {
          clearTimeout(t)
          vm.setVolume(n1, n2, n3)
          timerDestroy()
          return
        }
        const $volume = new Volume({
          propsData: {
            total: 30,
            n1,
            n2,
            n3
          },
          methods: {
            destroy: () => {
              this.close()
            }
          },
          beforeDestroy () {
            document.body.removeChild(vm.$el)
            vm.target = null
            vm.destroy = null
            vm = null
          }
        })
        vm = $volume.$mount()
        const el = vm.$el
        document.body.appendChild(el)
        timerDestroy()
      },
      close () {
        if (vm) {
          vm.$destroy()
        }
      }
    }
  }
}
