export function signalSelect (obj) {
  var str = obj.signalSTR
  if (str <= 0 && str > -50) {
    return '4'
  } else if (str <= -50 && str > -70) {
    return '3'
  } else if (str <= -70 && str > -80) {
    return '2'
  } else if (str <= -80 && str > -100) {
    return '1'
  } else if (str <= -100) {
    return '0'
  }
}
const getLocalWifi = () => {
  let wifilist = localStorage.getItem('wifilist')
  if (wifilist) {
    wifilist = JSON.parse(wifilist)
  } else {
    wifilist = []
  }
  return wifilist
}
const saveLocalWifi = () => {
  localStorage.setItem('wifilist', JSON.stringify(wifiSaveList))
  console.log('-----saveWifi:' + JSON.stringify(wifiSaveList))
}
const wifiSaveList = getLocalWifi()
const wifiListMaxLength = 2
export const hasSSid = (ssid) => {
  let index = -1
  for (let i = 0; i < wifiSaveList.length; i++) {
    if (ssid === wifiSaveList[i].ssid) {
      index = i
      break
    }
  }
  return index
}
export const saveWifi = (ssid, pwd) => {
  const i = hasSSid(ssid)
  if (i >= 0) {
    wifiSaveList.splice(i, 1)
    wifiSaveList.push({ ssid, pwd })
  } else if (wifiSaveList.length < wifiListMaxLength) {
    wifiSaveList.push({ ssid, pwd })
  } else {
    wifiSaveList.shift()
    wifiSaveList.push({ ssid, pwd })
  }
  saveLocalWifi()
}
export const getWifiList = () => {
  return wifiSaveList
}
export const removeWifiBySSid = (ssid) => {
  const i = hasSSid(ssid)
  if (i >= 0) {
    wifiSaveList.splice(i, 1)
    saveLocalWifi()
  }
}
