<template>
  <div>{{ dateTime | formate }}</div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'date',
  filters: {
    formate: function (value) {
      if (!value) return ''
      return moment(value).format('HH:mm')
    }
  },
  data () {
    return {
      dateTime: Date.now(),
      timer: null
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.dateTime = Date.now()
    }, 500)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
