function EventTarget () {
  this.handlers = {}
}
EventTarget.prototype = {
  constructor: EventTarget,
  addEventListener: function (type, handler) {
    if (typeof this.handlers[type] === 'undefined') {
      this.handlers[type] = []
    }
    this.handlers[type].push(handler)
  },
  fire: function (type, event) {
    if (this.handlers[type] instanceof Array) {
      var handlers = this.handlers[type]
      for (var i = handlers.length - 1; i >= 0; i--) {
        handlers[i](event)
      }
    }
  },
  removeEventListener: function (type, handler) {
    if (this.handlers[type] instanceof Array) {
      var handlers = this.handlers[type]
      for (var i = 0, len = handlers.length; i < len; i++) {
        if (handlers[i] === handler) {
          handlers.splice(i, 1)
          break
        }
      }
    }
  }
}
export default EventTarget
